export enum Typography {
    ARTICLE_HEADING_LARGE = 'article-heading-large',
    ARTICLE_HEADING_MEDIUM = 'article-heading-medium',
    ARTICLE_HEADING_SMALL = 'article-heading-small',
    CAPTION_MEDIUM = 'caption-medium',
    CAPTION_SMALL = 'caption-small',
    HEADING_LARGE = 'heading-large',
    HEADING_MEDIUM = 'heading-medium',
    HEADING_SMALL = 'heading-small',
    HEADING_X_SMALL = 'heading-x-small',
    LINK_LIST = 'link-list',
    LINK_MEDIUM = 'link-medium',
    LINK_NAVIGATION = 'link-navigation',
    LINK_SMALL = 'link-small',
    LINK_SUB_NAVIGATION = 'link-sub-navigation',
    PARAGRAPH_LARGE = 'paragraph-large',
    PARAGRAPH_MEDIUM = 'paragraph-medium',
    PREMIUM_HEADING_LARGE = 'premium-heading-large',
    PREMIUM_HEADING_MEDIUM = 'premium-heading-medium',
    PREMIUM_LINK_LIST_HEADING = 'premium-link-list-heading',
    TAG_LARGE = 'tag-large',
    TAG_MEDIUM = 'tag-medium',
    TAG_SMALL = 'tag-small',
}

export const TYPOGRAPHY_CATEGORIES: Record<string, Typography[]> = {
    heading: [Typography.HEADING_LARGE, Typography.HEADING_MEDIUM, Typography.HEADING_SMALL],
    article: [
        Typography.ARTICLE_HEADING_LARGE,
        Typography.ARTICLE_HEADING_MEDIUM,
        Typography.ARTICLE_HEADING_SMALL,
    ],
    paragraph: [Typography.PARAGRAPH_LARGE, Typography.PARAGRAPH_MEDIUM],
    premium: [
        Typography.PREMIUM_HEADING_LARGE,
        Typography.PREMIUM_HEADING_MEDIUM,
        Typography.PREMIUM_LINK_LIST_HEADING,
    ],
    caption: [Typography.CAPTION_MEDIUM, Typography.CAPTION_SMALL],
    tag: [Typography.TAG_LARGE, Typography.TAG_MEDIUM, Typography.TAG_SMALL],
    link: [
        Typography.LINK_MEDIUM,
        Typography.LINK_SMALL,
        Typography.LINK_LIST,
        Typography.LINK_NAVIGATION,
        Typography.LINK_SUB_NAVIGATION,
    ],
};
