import { CuratedNewsItem, FeaturedCarouselLayout } from '@common/clients/api';

import { FeaturedCarrouselItem } from './FeaturedCarrouselItem';

import styles from './FeaturedCarrousel.module.scss';

export interface Props {
    items: CuratedNewsItem[];
    layout: FeaturedCarouselLayout;
}

const ITEMS_CLASS_NAME: Record<FeaturedCarouselLayout, string> = {
    [FeaturedCarouselLayout.EXPANDED_FOOTER]: styles['items-expanded-footer'],
    [FeaturedCarouselLayout.EXPANDED_SIDE]: styles['items-expanded-side'],
    [FeaturedCarouselLayout.MINIFIED_SIDE]: styles['items-minified-side'],
    [FeaturedCarouselLayout.SINGLE_MAIN]: styles['items-single-main'],
    [FeaturedCarouselLayout.TWIN_MAINS]: styles['items-twin-mains'],
};

export const FeaturedCarrouselDesktop = ({ items, layout }: Props) => {
    let mainItems: CuratedNewsItem[] = items;
    let sideItems: CuratedNewsItem[] = [];

    if (layout === FeaturedCarouselLayout.MINIFIED_SIDE) {
        mainItems = items.slice(0, 1);
        sideItems = items.slice(1);
    }

    return (
        <div className={styles['desktop-container']}>
            <ul className={`${styles['items']} ${ITEMS_CLASS_NAME[layout]}`}>
                {mainItems.map((item, index) => (
                    <FeaturedCarrouselItem key={index} index={index} item={item} layout={layout} />
                ))}
                {sideItems.length ? (
                    <div className={styles['side-container']}>
                        {sideItems.map((item, index) => (
                            <FeaturedCarrouselItem
                                key={index}
                                index={index + mainItems.length}
                                item={item}
                                layout={layout}
                            />
                        ))}
                    </div>
                ) : null}
            </ul>
        </div>
    );
};
