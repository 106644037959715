import { FeaturedCarouselLayout } from '@common/clients/api';
import { Typography } from '@common/types/Typography';
import { DefaultDynamicSizes } from '@web/atoms/WebpImage';

export enum ItemVariant {
    BOTTOM_CAPTION_BOX = 'bottom-caption-box',
    BOTTOM_CAPTION_BOX_INVERTED = 'bottom-caption-box-inverted',
    FLOATING_CAPTION_BOX_INVERTED = 'floating-caption-box-inverted',
    FOOTER = 'footer',
    MINIFIED = 'minified',
    NO_CAPTION_BOX = 'no-caption-box',
}

export interface ItemConfig {
    imageSizes?: DefaultDynamicSizes;
    large?: boolean;
    typography?: Typography;
    variant: ItemVariant;
}

export type LayoutConfig = Record<FeaturedCarouselLayout, ItemConfig[]>;
