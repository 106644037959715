import { memo } from 'react';

import { CuratedNewsItem, FeaturedCarouselLayout } from '@common/clients/api';
import { LAYOUT_ITEM_COUNT, LEGACY_ITEM_COUNT_DIFF } from '@common/types/LayoutItemCount';

import { FeaturedCarrouselDesktop } from './FeaturedCarrouselDesktop';
import { FeaturedCarrouselMobile } from './FeaturedCarrouselMobile';

import styles from './FeaturedCarrousel.module.scss';

export interface Props {
    items: CuratedNewsItem[];
    layout?: FeaturedCarouselLayout;
    legacyLayout?: boolean;
}

const getFeaturedItemCount = (layout: FeaturedCarouselLayout, legacyLayout: boolean): number => {
    const baseCount = LAYOUT_ITEM_COUNT[layout];

    // TODO: When we migrated all platforms to Curation we can remove legacyLayout.
    if (legacyLayout) {
        const legacyDiff = LEGACY_ITEM_COUNT_DIFF[layout] ?? 0;
        return baseCount + legacyDiff;
    }

    return baseCount;
};

const FeaturedCarrouselComponent = ({
    items,
    layout = FeaturedCarouselLayout.EXPANDED_SIDE,
    legacyLayout = false,
}: Props) => {
    const featuredItems = items.slice(0, getFeaturedItemCount(layout, legacyLayout));
    return (
        <aside className={styles['FeaturedCarrousel']}>
            <FeaturedCarrouselMobile items={featuredItems} layout={layout} />
            <FeaturedCarrouselDesktop items={featuredItems} layout={layout} />
        </aside>
    );
};

export const FeaturedCarrousel = memo(FeaturedCarrouselComponent);
