import { FeaturedCarouselLayout } from '@common/clients/api/types.gen';

export const LAYOUT_ITEM_COUNT: Record<FeaturedCarouselLayout, number> = {
    [FeaturedCarouselLayout.EXPANDED_SIDE]: 4,
    [FeaturedCarouselLayout.MINIFIED_SIDE]: 3,
    [FeaturedCarouselLayout.TWIN_MAINS]: 2,
    [FeaturedCarouselLayout.EXPANDED_FOOTER]: 4,
    [FeaturedCarouselLayout.SINGLE_MAIN]: 1,
};

// TODO: When we migrated all platforms to Curation we can remove legacyLayout. This is the difference of number of items in the legacy layout.
// compared to the newer LAYOUT_ITEM_COUNT for curation
export const LEGACY_ITEM_COUNT_DIFF: Partial<Record<FeaturedCarouselLayout, number>> = {
    [FeaturedCarouselLayout.MINIFIED_SIDE]: 2,
    [FeaturedCarouselLayout.EXPANDED_SIDE]: -1,
};
