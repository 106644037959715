import { FeaturedCarouselLayout } from '@common/clients/api';
import { Typography } from '@common/types/Typography';
import { ThemeConfig, useThemeConfig } from '@web/hooks/useThemeConfig';

import { ItemVariant, LayoutConfig } from './FeaturedCarrousel.types';

export interface FeaturedCarrouselConfigProps {
    layoutConfig?: Partial<LayoutConfig>;
    showCommentCount: boolean;
    showTags: boolean;
    typography: Typography;
}

const THEME_CONFIG: ThemeConfig<FeaturedCarrouselConfigProps> = {
    defaults: {
        showCommentCount: true,
        showTags: true,
        typography: Typography.HEADING_MEDIUM,
    },
    byPlatform: {
        bi: {
            showCommentCount: false,
            typography: Typography.HEADING_LARGE,
            layoutConfig: {
                [FeaturedCarouselLayout.EXPANDED_SIDE]: [
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX_INVERTED,
                    },
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX_INVERTED,
                    },
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX_INVERTED,
                    },
                ],
                [FeaturedCarouselLayout.SINGLE_MAIN]: [
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX_INVERTED,
                    },
                ],
                [FeaturedCarouselLayout.EXPANDED_FOOTER]: [
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX_INVERTED,
                    },
                ],
            },
        },
        br: {
            showTags: false,
            layoutConfig: {
                [FeaturedCarouselLayout.EXPANDED_SIDE]: [
                    {
                        variant: ItemVariant.FLOATING_CAPTION_BOX_INVERTED,
                    },
                    {
                        variant: ItemVariant.FLOATING_CAPTION_BOX_INVERTED,
                    },
                    {
                        variant: ItemVariant.FLOATING_CAPTION_BOX_INVERTED,
                    },
                ],
                [FeaturedCarouselLayout.EXPANDED_FOOTER]: [
                    {
                        variant: ItemVariant.FLOATING_CAPTION_BOX_INVERTED,
                    },
                ],
                [FeaturedCarouselLayout.MINIFIED_SIDE]: [
                    {
                        variant: ItemVariant.FLOATING_CAPTION_BOX_INVERTED,
                    },
                ],
                [FeaturedCarouselLayout.SINGLE_MAIN]: [
                    {
                        variant: ItemVariant.FLOATING_CAPTION_BOX_INVERTED,
                    },
                ],
                [FeaturedCarouselLayout.TWIN_MAINS]: [
                    {
                        variant: ItemVariant.FLOATING_CAPTION_BOX_INVERTED,
                    },
                    {
                        variant: ItemVariant.FLOATING_CAPTION_BOX_INVERTED,
                    },
                ],
            },
        },
        mh: {
            showCommentCount: false,
            typography: Typography.ARTICLE_HEADING_MEDIUM,
            layoutConfig: {
                [FeaturedCarouselLayout.EXPANDED_SIDE]: [
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX,
                        typography: Typography.HEADING_LARGE,
                    },
                ],
                [FeaturedCarouselLayout.SINGLE_MAIN]: [
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX,
                        typography: Typography.HEADING_LARGE,
                    },
                ],
                [FeaturedCarouselLayout.EXPANDED_FOOTER]: [
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX,
                        typography: Typography.HEADING_LARGE,
                    },
                ],
            },
        },
        rw: {
            showCommentCount: false,
            layoutConfig: {
                [FeaturedCarouselLayout.EXPANDED_SIDE]: [
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX_INVERTED,
                        typography: Typography.HEADING_LARGE,
                    },
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX_INVERTED,
                    },
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX_INVERTED,
                    },
                ],
                [FeaturedCarouselLayout.SINGLE_MAIN]: [
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX_INVERTED,
                        typography: Typography.HEADING_LARGE,
                    },
                ],
                [FeaturedCarouselLayout.EXPANDED_FOOTER]: [
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX_INVERTED,
                        typography: Typography.HEADING_LARGE,
                    },
                ],
            },
        },
        vi: {
            showTags: true,
            showCommentCount: false,
            typography: Typography.PREMIUM_HEADING_MEDIUM,
        },
        wh: {
            showCommentCount: false,
            layoutConfig: {
                [FeaturedCarouselLayout.EXPANDED_SIDE]: [
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX,
                        typography: Typography.HEADING_LARGE,
                    },
                ],
                [FeaturedCarouselLayout.SINGLE_MAIN]: [
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX,
                        typography: Typography.HEADING_LARGE,
                    },
                ],
                [FeaturedCarouselLayout.EXPANDED_FOOTER]: [
                    {
                        variant: ItemVariant.BOTTOM_CAPTION_BOX,
                        typography: Typography.HEADING_LARGE,
                    },
                ],
            },
        },
    },
};

export const useFeaturedCarouselThemeConfig = () => useThemeConfig(THEME_CONFIG);
