import { CuratedNewsItem, FeaturedCarouselLayout } from '@common/clients/api';

import { FeaturedCarrouselItem } from './FeaturedCarrouselItem';
import { FeaturedCarrouselMobileNav } from './FeaturedCarrouselMobileNav';

import styles from './FeaturedCarrousel.module.scss';

interface Props {
    items: CuratedNewsItem[];
    layout: FeaturedCarouselLayout;
}

export const FeaturedCarrouselMobile = ({ items, layout }: Props) => {
    return (
        <div className={styles['mobile-container']}>
            <ul className={`${styles['items']} ${styles['items-slideshow']}`} data-slideshow>
                {items.map((item, index) => (
                    <FeaturedCarrouselItem key={index} index={index} item={item} layout={layout} isMobile />
                ))}
            </ul>
            <FeaturedCarrouselMobileNav items={items} />
        </div>
    );
};
