'use client';

import { useEffect, useRef, useState } from 'react';
import { range, throttle } from 'lodash';

import { CuratedNewsItem } from '@common/clients/api';

import styles from './FeaturedCarrousel.module.scss';

interface Props {
    items: CuratedNewsItem[];
}

const getSlideshowElement = (barElement?: HTMLUListElement | null) => {
    return barElement?.parentElement?.querySelector('[data-slideshow]');
};

export const FeaturedCarrouselMobileNav = ({ items }: Props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const barElementRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        const slideshowElement = getSlideshowElement(barElementRef.current);

        const listener = throttle(() => {
            const firstSlideElement = slideshowElement?.firstElementChild;
            const rect = firstSlideElement?.getBoundingClientRect();
            if (rect) {
                const width = rect.right - rect.left;
                const index = Math.round(rect.left / -width);
                setCurrentIndex(index);
            }
        }, 250);

        slideshowElement?.addEventListener('scroll', listener);

        return () => {
            slideshowElement?.removeEventListener('scroll', listener);
        };
    }, []);

    return (
        <ul className={styles['slider-bar']} ref={barElementRef}>
            {range(0, items.length).map((index) => (
                <li
                    className={`${styles['slider-bar-item']} ${index === currentIndex ? styles['active'] : ''}`}
                    id={`indicator${index}`}
                    key={index}
                    onClick={() => {
                        const slideshowElement = getSlideshowElement(barElementRef.current);
                        if (slideshowElement) {
                            const rect = slideshowElement.getBoundingClientRect();
                            const width = rect.right - rect.left;
                            slideshowElement.scrollTo(index * width, 0);
                        }
                    }}
                />
            ))}
        </ul>
    );
};
